<template>
    <div>
        <img class="guid-img" src="https://seetop-1257860468.file.myqcloud.com/html/booking/ticket_process1126.jpg">
        <float-nav></float-nav>
    </div>
</template>

<script>
  import FloatNav from '../../components/common/FloatNav'
  export default {
    name: 'GuidPft',
    components: { FloatNav }
  }
</script>

<style scoped>
    .guid-img {
        width: 100%;
    }
</style>
